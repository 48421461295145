<template>
    <header class="page-header">
        <div class="header-content">
            <div class="left">
                <router-link to="/"><img src="../../assets/images/mobile/logo.png" /> </router-link>
            </div>
            <div class="right">
                <wallet />
            </div>
            <div class="nav">
                <div class="nav-button" @click="open = true">
                    <i class="el-icon-minus"></i>
                    <i class="el-icon-minus"></i>
                    <i class="el-icon-minus"></i>
                </div>
                <div class="nav-content" :class="{open: open}">
                    <div class="nav-warp">
                        <div class="nav-item" :class="{active: $route.path === '/'}">
<!--                            <span @click="goto('/', 0)">Home</span>-->
                        </div>
                        <div class="menu-footer">
                            <div class="lang" @click="toggleLang">
                                <img src="../../assets/images/lang.png" />
                                <span>{{cpd_lang}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="nav-bg" @click="open = false"></div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {local} from "../../config/constant"
import wallet from "./wallet"

export default {
    name: 'Header',
    components: {
        wallet
    },
    data() {
        return {
            open: false,
            active: 0
        }
    },
    computed: {
        cpd_lang() {
            if (this.$i18n.locale === 'en-US') {
                return '中文'
            } else {
                return 'English'
            }
        }
    },
    methods: {
        goto(path) {
            this.$router.push(path)
        },
        toggleLang() {
            this.open = false
            let v = this.$store.state.lang
            if (v === 'zh-CN') {
                v = 'en-US'
            } else {
                v = 'zh-CN'
            }
            this.$store.commit('setLang', v)
            this.$i18n.locale = v
            localStorage.setItem(local.LOCALE, v)
        }
    }
}
</script>

<style lang="less">
.page-header {
    display: flex;
    height: 3.6rem;
    border-bottom: solid 1px #000;
    justify-content: center;
    .header-content {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left {
            display: flex;
            width: 4rem;
            margin-left: 1rem;
            a {
                display: flex;
                align-items: center;
                img {
                    height: 2.2rem;
                }
            }
        }
        .nav {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            .nav-button {
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                position: relative;
                i {
                    font-size: 2.4rem;
                    height: 0.6rem;
                    position: relative;
                    top: -0.8rem;
                }
            }
            .nav-content {
                position: fixed;
                z-index: 999;
                right: 0;
                width: 100vw;
                height: 100vh;
                top: 0;
                transform: translateX(100vw);
                transition: transform .5s;
                display: flex;
                justify-content: flex-end;
                &.open {
                    transform: translateX(0vw);
                }
                .nav-warp {
                    width: 50vw;
                    background: #FDCB5B;
                    height: 100%;
                    position: relative;
                    z-index: 9;
                    box-sizing: border-box;
                    box-shadow: 1px 1px 3rem #000;
                    padding: 2rem 0.6rem 2rem 0.6rem;
                    .nav-item {
                        padding: 1rem 1rem;
                        border-bottom: solid 1px #000;
                        span {
                            font-weight: bold;
                            font-family: ChalkboardBold;
                            cursor: pointer;
                            font-size: 1.2rem;
                        }
                        &.active {
                            span {
                                color: #fff;
                            }
                        }
                    }
                    .menu-footer {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        padding: 12px 36px 36px 36px;
                        display: flex;
                        justify-content: flex-end;
                        border-top: solid 1px #000;
                        .lang {
                            width: 100%;
                            height: 28px;
                            display: flex;
                            img {
                                width: 28px;
                                height: 28px;
                            }
                            span {
                                font-size: 20px;
                                padding-left: 6px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .nav-bg {
                    position: absolute;
                    width: 100vw;
                    height: 100vh;
                }
            }
        }
        .right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>
