<template>
    <div class="wallet-component" :class="currentChain ? 'current-bsc' : 'current-heco'">
        <button
            v-if="cpd_account"
            :class="{connected: cpd_account}"
        >
            <div class="switch-chain">
                <i><img :src="hecoLogo" /> </i>
                <el-switch v-model="currentChain" @change="onSwitchChain" active-color="#000000" inactive-color="#D3A54A" />
                <i><img :src="bscLogo" /> </i>
            </div>
            <span @click="disConnectWallet">{{cpd_account ? cpd_accountText : $t('menu.connect')}}</span>
        </button>
        <button v-else @click="connectWallet" :class="{connected: cpd_account}">{{cpd_account ? cpd_accountText : $t('menu.connect')}}</button>
        <el-dialog
            :title="'Your wallet'"
            :visible.sync="visible"
            :width="$mobile ? '370px' : '620px'"
            :before-close="handleClose"
        >
            <div class="header-wallet-dialog" :class="$mobile ? 'mobile' : ''">
                <div class="address">{{cpd_account}}</div>
                <div class="buttons">
                    <div class="button-item">
                        <a target="_blank" :href="`https://testnet.hecoinfo.com/address/${cpd_account}`">
                            <span>View on Heco</span>
                            <img src="../../assets/images/pc/link-icon.png" />
                        </a>
                    </div>
                    <div class="button-item">
                        <span>Copy Address</span>
                        <img src="../../assets/images/pc/copy-icon.png" />
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Web3 from 'web3'
import {tokens, ChainName, netWork, chainList, ChainIds} from '../../config/constant'
import pageMixin from "../../mixins/pageMixin"
import BigNumber from 'bignumber.js'
BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export default {
    name: 'Header',
    mixins: [pageMixin],
    data() {
        return {
            visible: false,
            currentChain: false,
            hecoLogo: chainList[this.getChainName(1)].icon,
            bscLogo: chainList[this.getChainName(0)].icon
        }
    },
    computed: {
        cpd_account() {
            return this.$store.state.account
        },
        cpd_accountText() {
            const account = this.cpd_account
            if (!account) {
                return ''
            }
            const len = account.length
            const arr = account.split('')
            arr.splice(4, len - 8, '....')
            return arr.join('')
        }
    },
    watch: {
        '$store.state.chainId'(v) {
            this.currentChain = (ChainName[v] === 'HECO' || ChainName[v] === 'HECO_TESTNET') > 0 ? false : true
            setTimeout(() => {
                this.getMncBalance(this.cpd_account, ChainName[v])
                this.getGasBalance(this.cpd_account)
            }, 100)
        },
        '$store.state.reload'() {
            setTimeout(() => {
                this.getMncBalance(this.cpd_account, ChainName[this.$store.state.chainId])
                this.getGasBalance(this.cpd_account)
            }, 100)
        },
        currentChain(v) {
            const chainName = v ? this.getChainName(0) : this.getChainName(1)
            console.log(chainName, 'chainName')
            if (ChainIds[chainName] !== this.$web3.currentProvider.chainId) {
                this.switchChain(chainName)
            }
        }
    },
    created() {
        if (this.$web3.currentProvider) {
            this.$store.commit('setAccount', this.$web3.currentProvider.selectedAddress)
            sessionStorage.setItem('meta_mask_account', this.$web3.currentProvider.selectedAddress)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.connectWallet()
        })
    },
    methods: {
        connectWallet() {
            this.$vueweb3.connectWallet(this,{
                accountsChanged: (accounts) => {
                    const address = accounts[0]
                    const chainId = this.$web3.currentProvider.chainId
                    this.$store.commit('setChainId', chainId)
                    this.$store.commit('setAccount', address)
                    this.getMncBalance(address)
                    this.getGasBalance(address)
                },
                chainChanged: (chainId) => {
                    console.log(chainId, 9999999999)
                    this.$store.commit('setChainId', chainId)
                }
            })
        },
        getGasBalance(address) {
            this.$web3.eth.getBalance(address).then(res => {
                this.$store.commit('setFromBalance', this.$web3.utils.fromWei(res, 'ether'))
                this.$web3.eth.getGasPrice().then(gasPrice => {
                    const gasAmount = new BigNumber(210000)
                    const totalPrice = gasAmount.times(gasPrice).toString()
                    const minGas = this.$web3.utils.fromWei(totalPrice, 'ether')
                    this.$store.commit('setFromGasPrice', this.$web3.utils.fromWei(gasPrice, 'Gwei'))
                    this.$store.commit('setMinFromGasAmount', minGas)
                })
            })
            const toChain = this.$store.state.toChain || sessionStorage.getItem('TO-CHAIN')
            const newProvider = new Web3(netWork[toChain].rpcUrls[0])
            newProvider.eth.getBalance(address).then(res => {
                this.$store.commit('setToBalance', this.$web3.utils.fromWei(res, 'ether'))
                newProvider.eth.getGasPrice().then(gasPrice => {
                    const gasAmount = new BigNumber(210000)
                    const totalPrice = gasAmount.times(gasPrice).toString()
                    const minGas = this.$web3.utils.fromWei(totalPrice, 'ether')
                    this.$store.commit('setToGasPrice', this.$web3.utils.fromWei(gasPrice, 'Gwei'))
                    this.$store.commit('setMinToGasAmount', minGas)
                })
            })
        },
        switchChain(v) {
            this.$vueweb3.wallet_addEthereumChain({
                params: [
                    netWork[v]
                ]
            }, () => {
                console.log(this.$web3.currentProvider.chainId, 'this.$web3.currentProvider.chainId')
                this.$store.commit('setChainId', this.$web3.currentProvider.chainId)
            })
        },
        onSwitchChain(v) {

        },
        disConnectWallet() {
            this.visible = true
        },
        getChainName(type) {
            const prod = process.env.NODE_ENV === 'production' && process.env.VUE_APP_NODE_ENV === 'production'
            if (type) {
                return prod ? 'HECO' : 'HECO_TESTNET'
            } else {
                return prod ? 'BSC' : 'BSC_TESTNET'
            }
        },
        handleClose() {
            this.visible = false
        },
        getMncBalance(address, chainName) {
            if (!chainName || chainName === 'undefined') {
                return
            }
            const mockERC20 = this.$vueweb3.contract(this.$abi.ERC20,  tokens[chainName]).methods
            mockERC20.balanceOf(address).call().then(result => {
                const balance = this.$web3.utils.fromWei(result)
                this.$store.commit('setBalance', balance)
            })
        }
    }
}
</script>

<style lang="less">
.wallet-component {
    display: flex;
    align-items: center;
    &.current-bsc {
        button {
            background-color: #212121;
            span {
                color: #fff;
            }
        }
    }
    button {
        background: transparent;
        padding: 6px 12px;
        border-radius: 11px;
        color: #000;
        cursor: pointer;
        font-family: ChalkboardBold;
        font-size: 16px;
        &.connected {
            border: solid 3px #000;
            display: flex;
            align-items: center;
            .switch-chain {
                display: flex;
                padding-right: 6px;
                align-items: center;
                i {
                    width: 24px;
                    height: 24px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .el-switch {
                    margin: 0 4px;
                    .el-switch__core {
                        width: 34px!important;
                    }
                }
            }
        }
    }
}
.header-wallet-dialog {
    padding: 0 24px;
    .address {
        background-color: #fff;
        border-radius: 13px;
        font-family: ChalkboardSEBold;
        font-size: 20px;
        line-height: 46px;
        text-align: center;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
        padding: 0 8px;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        .button-item {
            display: flex;
            margin: 0 24px;
            a {
                display: flex;
            }
            span {
                font-size: 18px;
                color: #fff;
                display: flex;
                align-items: center;
                font-family: ChalkboardSEBold;
                padding-right: 12px;
            }
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
    &.mobile {
        padding: 0 0.4rem;
        .address {
            background-color: #fff;
            border-radius: 0.4rem;
            font-family: ChalkboardSEBold;
            font-size: 0.9rem;
            line-height: 2.6rem;
            text-align: center;
            color: #000;
        }
        .buttons {
            display: flex;
            justify-content: space-between;
            padding-top: 1rem;
            .button-item {
                display: flex;
                margin: 0 1rem;
                a {
                    display: flex;
                }
                span {
                    font-size: 1rem;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    font-family: ChalkboardSEBold;
                    padding-right: 0.2rem;
                    line-height: 1rem;
                }
                img {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }
}
</style>
